import React from 'react';
import PropTypes from 'prop-types';
import PaymentArticle from './PaymentArticle';
import iconAdd from '../../../../assets/icons/add.svg';
import iconRemove from '../../../../assets/icons/remove.svg';
import { BuyPlansContext } from '../BuyPlansContext';
import { deletePaymentByWalletId, getWalletDetails } from '../../providers/EstoreProvider';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import Spinner from '../../Spinner';
import { maskPaymentCardNumber, getStoredPayments } from '../buyPlansUtil';
import FormAddNewPaymentStripe from './FormAddNewPaymentStripe';

export default class Payment extends React.Component {
  static contextType = BuyPlansContext;

  static propTypes = {
    showAlert: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    card: PropTypes.object,
    setCard: PropTypes.func,
    onContinue: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    availablePaymentoptions: [],
    customer: null,
    storedPaymentOptions: [],
    useNewPayment: false,
    newbillingAddress: null
  };

  componentDidMount () {
    this.initData();

    if (document.URL.includes('addPaymentStatus=failure')) {
      this.props.showAlert(this.context.translator.t('buyplans_purchase_verify_creditcard_error_message'));
    }
  }

  initData = async () => {
    this.setState({ loading: true });
    try {
      const response = await getWalletDetails();
      const availablePaymentoptions = response?.data?.availablePaymentoptions || [];
      const customer = response?.data?.customer;
      const storedPaymentOptions =
        (response?.data?.storedPaymentOptions && getStoredPayments(response.data.storedPaymentOptions)) || [];

      this.setState({ loading: false, availablePaymentoptions, customer, storedPaymentOptions });
    } catch (error) {
      const alert = findErrorMessage(error);
      this.props.showAlert(alert);
      this.setState({ loading: false });
    }
  };

  deletePayment = async reference => {
    try {
      await deletePaymentByWalletId(reference);
      this.initData();
    } catch (error) {
      const alert = findErrorMessage(error);
      this.props.showAlert(alert);
    }
  };

  onChange = (paymentOption, cvv = '') => {
    const card = {
      token: paymentOption?.reference || paymentOption?.Id,
      cvv,
      carrierNumber: paymentOption?.carrierNumber,
      name: paymentOption?.name || paymentOption?.Card?.Brand
    };
    this.props.setCard(card);
  };

  onClickContinue = e => {
    e.preventDefault();
    const form = e.currentTarget.form;

    if (form?.checkValidity() === false) {
      form?.reportValidity();

      return;
    }

    this.props.onContinue();
  };

  toggleNewPaymentForm = () => {
    this.setState({ useNewPayment: !this.state.useNewPayment });
  };

  render () {
    const { translator, contentURL, marketingConfigurationHash } = this.context;
    const items = this.props.items || [];

    const { card } = this.props;
    const { storedPaymentOptions, customer, availablePaymentoptions } = this.state;

    return (
      <>
        {items.map(item => (
          <PaymentArticle
            key={item.sku}
            item={item}
            configuration={marketingConfigurationHash[item.marketingConfigurationId]}
            contentURL={contentURL}
            translator={translator}
          />
        ))}
        <form className='w-100 d-flex flex-column align-items-center' onSubmit={() => false}>
          <h2 className='mt-5 mb-3 font-weight-light'>{translator.t('buyplans_select_a_payment')}</h2>
          <section className='col col-md-12 row justify-content-center justify-content-md-between'>
            <section className='col-auto d-flex flex-column mb-4'>
              {storedPaymentOptions?.length > 0 &&
                storedPaymentOptions.map((po, index) => (
                  <React.Fragment key={`paymentOption-${index}-${po?.code}`}>
                    <article className='w-100 d-flex flex-row align-items-center mt-3'>
                      <input
                        type='radio'
                        id={`paymentOption-${index}-${po?.code}`}
                        name='paymentOption'
                        required={true}
                        checked={card?.token === po?.reference}
                        className='cwp text-left m-0'
                        onChange={() => this.onChange(po)}
                      />
                      <label htmlFor={`paymentOption-${index}-${po?.code}`} className='ml-2 mr-3 my-0'>{`${
                        po?.name
                      } ${maskPaymentCardNumber(po?.carrierNumber, 4)}`}</label>
                      <button
                        type='button'
                        className='carnetLink delete ml-auto mt-0'
                        onClick={() => this.deletePayment(po?.reference)}
                      >
                        {translator.t('buyplans.label.delete-payment')}
                      </button>
                    </article>
                    {card?.token === po?.reference && (
                      <>
                        <label htmlFor='txtCVV' className='cvv'>
                          {translator.t('buyplans.label.enter-cvv')}
                        </label>
                        <input
                          id='txtCVV'
                          type='password'
                          minLength={3}
                          maxLength={4}
                          required={true}
                          pattern='[0-9]+'
                          value={card?.cvv}
                          className='cwp cvv'
                          title={translator.t('buyplans_enter_cvv_number_text')}
                          onChange={e => this.onChange(po, e.currentTarget.value)}
                        />
                      </>
                    )}
                  </React.Fragment>
                ))}
              {this.state.loading && <Spinner />}
            </section>
            <button
              type='button'
              className='carnetLink col-auto align-self-start px-0'
              onClick={this.toggleNewPaymentForm}
            >
              <img src={this.state.useNewPayment ? iconRemove : iconAdd} />
              &nbsp;
              <u>{translator.t('buyplans.label.add-a-new-payment')}</u>
            </button>
          </section>
          {this.state.useNewPayment === false && (
            <>
              <button
                type='button'
                className='cwp cwpPrimary mt-5'
                onClick={this.onClickContinue}
                disabled={storedPaymentOptions?.length === 0}
              >
                {translator.t('button.continue')}
              </button>
              <button type='button' className='carnetLink mt-5' onClick={this.props.onCancel}>
                {translator.t('button.cancel')}
              </button>
            </>
          )}
        </form>
        {this.state.useNewPayment && (
          <FormAddNewPaymentStripe
            translator={translator}
            availablePaymentoptions={availablePaymentoptions}
            customer={customer}
            onCancel={() => this.toggleNewPaymentForm(false)}
            showAlert={this.props.showAlert}
          />
        )}
      </>
    );
  }
}
