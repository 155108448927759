export const getUrls = errorCode => {
  const urls = {
    SRCH_KEY_CORRESPONDS_BRAND_VW_COU_US: 'https://w-h-s.spr.us00.uat.con-veh.net/zev/vw/batteryinfo',
    SRCH_KEY_CORRESPONDS_BRAND_AUDI_COU_US: 'https://www.audiusa.com/en/compliance/battery-labels/',
    SRCH_KEY_CORRESPONDS_BRAND_BENTLEY_COU_US: 'https://w-h-s.spr.us00.uat.con-veh.net/zev/bentley/batteryinfo',
    SRCH_KEY_CORRESPONDS_BRAND_VW_COU_CA: 'https://w-h-s.spr.ca00.uat.con-veh.net/zev/vw/batteryinfo',
    SRCH_KEY_CORRESPONDS_BRAND_AUDI_COU_CA:
      'https://www.audi.ca/en/tools/navigation/layer/integrity-and-compliance/battery-labels/battery-labels',
    SRCH_KEY_CORRESPONDS_BRAND_BENTLEY_COU_CA: 'https://w-h-s.spr.ca00.uat.con-veh.net/zev/bentley/batteryinfo'
  };

  return urls[errorCode] || '';
};

export const getErrorMessage = (errorCode, translator) => {
  const errorMessages = {
    SRCH_KEY_CORRESPONDS_UNSUPPORTED_BRAND_COU: 'zev.error.noBatteryInfoAvailable',
    INVALID_SEARCH_CRITERIA: 'zev.error.noBatteryInfoAvailable',
    SEARCH_CRITERIA_NOT_FOUND: 'zev.error.noBatteryInfoAvailable',
    USER_NOT_AUTHORIZED: 'zev.error.batteryInfoNotAccessible',
    SEARCH_CRITERIA_NOT_ACCESSIBLE: 'zev.error.batteryInfoNotAccessible',
    SRCH_KEY_CORRESPONDS_BRAND_VW_COU_US: 'zev.error.searchKeyCorrespondsVwCountryUs',
    SRCH_KEY_CORRESPONDS_BRAND_AUDI_COU_US: 'zev.error.searchKeyCorrespondsAudiCountryUs',
    SRCH_KEY_CORRESPONDS_BRAND_BENTLEY_COU_US: 'zev.error.searchKeyCorrespondsBentleyCountryUs',
    SRCH_KEY_CORRESPONDS_BRAND_VW_COU_CA: 'zev.error.searchKeyCorrespondsVwCountryCa',
    SRCH_KEY_CORRESPONDS_BRAND_AUDI_COU_CA: 'zev.error.searchKeyCorrespondsAudiCountryCa',
    SRCH_KEY_CORRESPONDS_BRAND_BENTLEY_COU_CA: 'zev.error.searchKeyCorrespondsBentleyCountryCa'
  };

  const errorMessageKey = errorMessages[errorCode] || 'zev.error.noBatteryDataOrErrorInAPI';
  const url = getUrls(errorCode);

  return (
    <>
      {translator.t(errorMessageKey)}
      {url && (
        <>
          <a href={url} target='_blank' rel='noopener noreferrer'>
            {url}
          </a>
        </>
      )}
    </>
  );
};
