import React, { useState, useEffect } from 'react';
import './BatteryInfo.scss';
import { fetchBatteryDetails, downloadPSD } from '../providers/BatteryInfoProvider';
import { useLocation } from 'react-router-dom';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getErrorMessage } from './errorUtils';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const BatteryInfo = () => {
  const query = useQuery();
  const vehicleIdentifier = query.get('vehicle_identifier');
  const serialIdentifier = query.get('serial_identifier');
  let searchKey = vehicleIdentifier || serialIdentifier;

  const translator = getLocaleTranslator();
  const [formData, setFormData] = useState({
    vin: '',
    batteryId: '',
    batteryModuleId: ''
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [batteryData, setBatteryData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [displayConsentModal, setDisplayConsentModal] = useState(false);
  const [brand, setBrand] = useState(null);

  const brandNameQualified = {
    vw: 'Volkswagen',
    audi: 'Audi',
    bentley: 'Bentley'
  };

  useEffect(() => {
    const { vin, batteryId, batteryModuleId } = formData;
    const isVinValid = vin && vin.length === 17;
    setSubmitDisabled(!(isVinValid || batteryId || batteryModuleId));
    extractBrandName();
  }, [formData]);

  useEffect(() => {
    if (vehicleIdentifier || serialIdentifier) {
      setFormData({ vin: '', batteryId: '', batteryModuleId: '' });
      toggleModal();
      console.log(`Search Key from URL VIN: ${vehicleIdentifier} or serialIdentifier: ${serialIdentifier}`);
    }
  }, [vehicleIdentifier, serialIdentifier]);

  const extractBrandName = () => {
    const hash = window.location.hash;
    const pathSegments = hash.slice(1).split('/');
    setBrand(brandNameQualified[pathSegments[2]]);
  };

  const handleInputChange = e => {
    setBatteryData(null);
    setErrorMessage(null);
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async () => {
    const vin = vehicleIdentifier || formData.vin;
    const batterySerial = serialIdentifier || formData.batteryId || formData.batteryModuleId;
    try {
      setProcessing(true);
      const locale = localStorage.getItem('zevBatteryLanguage');
      let response = await fetchBatteryDetails(locale, brand, vin, batterySerial);
      if (response?.data) {
        setErrorMessage(null);
        setBatteryData(response?.data);
      } else if (response?.error?.errorCode) {
        const errorCode = response?.error?.errorCode;
        setErrorMessage(getErrorMessage(errorCode, translator));
      }
    } catch (error) {
      setErrorMessage(translator.t('zev.error.genericError'));
    } finally {
      setProcessing(false);
    }
  };

  const handleOkayButton = () => {
    setDisplayConsentModal(false);
    handleSubmit();
  };

  const toggleModal = () => {
    setDisplayConsentModal(!displayConsentModal);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    setDisplayConsentModal(true);
  };

  const downloadPSDPDF = async () => {
    const psdLink = batteryData?.psdLink;
    if (psdLink) {
      try {
        const response = await downloadPSD(psdLink);
        const contentType = response.headers['content-type'];
        if (contentType === 'application/octet-stream' && response?.data) {
          downloadPDF({
            data: response.data,
            fileName: translator.t('zev.psdFileName'),
            fileType: 'application/pdf'
          });
        } else {
          setErrorMessage(translator.t('zev.error.documentLinkExpired'));
        }
      } catch (err) {
        setErrorMessage(translator.t('zev.error.errorDownloadingPsdPdf'));
      }
    }
  };

  const downloadPDF = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  };

  const hostname = window.location.hostname.toLowerCase();
  const allowedDomains = ['.uat.con', '.int.con', 'localhost'];
  const isBatteryLookupAllowed = allowedDomains.some(domain => hostname.includes(domain));
  return (
    <div className='batteryInfo' role='main' id='main-content'>
      {!searchKey && isBatteryLookupAllowed && (
        <form onSubmit={handleFormSubmit} aria-labelledby='batteryInfoForm'>
          <div className='form-container'>
            <div className='form-group'>
              <label htmlFor='vin'>{translator.t('zev.vin')}</label>
              <input
                type='text'
                id='vin'
                value={formData.vin}
                onChange={handleInputChange}
                maxLength='17'
                className='form-control'
                aria-label={translator.t('zev.vinNumber')}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='batteryId'>{translator.t('zev.batteryId')}</label>
              <input
                type='text'
                id='batteryId'
                value={formData.batteryId}
                onChange={handleInputChange}
                className='form-control'
                aria-label={translator.t('zev.batteryIdentifier')}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='batteryModuleId'>{translator.t('zev.batteryModuleId')}</label>
              <input
                type='text'
                id='batteryModuleId'
                value={formData.batteryModuleId}
                onChange={handleInputChange}
                className='form-control'
                aria-label={translator.t('zev.batteryModuleIdentifier')}
              />
            </div>
          </div>
          <div className='w-25'>
            <button
              type='submit'
              className={submitDisabled ? 'cwp submit-disable-button' : 'cwp cwp-primary submit-button'}
              disabled={submitDisabled}
            >
              {translator.t('zev.submit')}
            </button>
          </div>
          <hr className='hr-line' />
        </form>
      )}

      {!batteryData && !isBatteryLookupAllowed && (
        <div className='lookup-error'> {translator.t('zev.error.manualInquiryBatteryNotAllowed')}</div>
      )}
      {processing && (
        <div className='mt-5 text-center'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'></span>
          </div>
        </div>
      )}
      {batteryData && !errorMessage && (
        <>
          <table aria-labelledby='batteryInfoTable'>
            <thead>
              <tr>
                <th colSpan='2'>{translator.t('zev.batteryInformation')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{translator.t('zev.batteryChemistry')}</td>
                <td>
                  {batteryData?.batteryChemistry?.srlId}: {batteryData?.batteryChemistry?.description}
                </td>
              </tr>
              <tr>
                <td>{translator.t('zev.anodeType')}</td>
                <td>
                  {batteryData?.anodeType?.anodeId}: {batteryData?.anodeType?.description}
                </td>
              </tr>
              <tr>
                <td>{translator.t('zev.cathodeType')}</td>
                <td>
                  <div aria-label={translator.t('zev.cathodeType')}>
                    <div>{batteryData?.cathodeType?.cathodSubIdentifier}</div>
                    <div>
                      {batteryData?.cathodeType?.cathodId}: {batteryData?.cathodeType?.description}
                    </div>
                    <div>
                      {batteryData?.cathodeType?.subIdentifier}: {batteryData?.cathodeType?.subDescription}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{translator.t('zev.manufacturer')}</td>
                <td>{batteryData?.manufacturer}</td>
              </tr>
              <tr>
                <td>{translator.t('zev.manufactureDate')}</td>
                <td>{batteryData?.manufactureDate}</td>
              </tr>
              <tr>
                <td>{translator.t('zev.minimumVoltage')}</td>
                <td>{batteryData?.minimumVoltage}</td>
              </tr>
              <tr>
                <td>{translator.t('zev.minimumCellVoltage')}</td>
                <td>{batteryData?.minimumCellVoltage}</td>
              </tr>
              <tr>
                <td>{translator.t('zev.ratedCapacity')}</td>
                <td>{batteryData?.ratedCapacity}</td>
              </tr>
              <tr>
                <td>{translator.t('zev.countIndividualCells')}</td>
                <td>{batteryData?.cellCount}</td>
              </tr>
              {batteryData?.moduleCount && (
                <tr>
                  <td>{translator.t('zev.moduleCount')}</td>
                  <td>{batteryData?.moduleCount}</td>
                </tr>
              )}
              <tr>
                <td>{translator.t('zev.hazardousSubstance')}</td>
                <td>
                  <div aria-label='Hazardous Substance'>
                    {Array.isArray(batteryData?.hazardousSubstance) &&
                      batteryData?.hazardousSubstance.map((hazardous, index) => (
                        <div key={index}>
                          {hazardous?.substance} ({translator.t('zev.casNo')}
                          {hazardous?.casNo})
                        </div>
                      ))}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{translator.t('zev.chemicalIdentifier')}</td>
                <td>{batteryData?.chemicalIdentifier}</td>
              </tr>
              {batteryData?.psdLink && (
                <tr>
                  <td>{translator.t('zev.psd')}</td>
                  <td>
                    <a
                      href='#'
                      className='download-link'
                      onClick={e => {
                        e.preventDefault();
                        downloadPSDPDF();
                      }}
                    >
                      {translator.t('zev.downloadPdf')}
                    </a>
                  </td>
                </tr>
              )}
              <tr>
                <td>{translator.t('zev.recalls')}</td>
                <td>
                  {batteryData?.activeCampaigns === true
                    ? translator.t('zev.recallFound')
                    : translator.t('zev.recallNotFound')}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <Modal isOpen={displayConsentModal} className='carnet-sg-modal' centered={true}>
        <ModalBody>
          <div className='font-weight-bold mb-4'>{translator.t('zev.disclaimerText')}</div>
          <div className='font-weight-bold'>{translator.t('zev.disclaimerWarning')}</div>
        </ModalBody>
        <ModalFooter className='noTopBorder'>
          <Button className='modal-primary-btn mx-auto' color='modal_background_color' onClick={handleOkayButton}>
            {translator.t('zev.okay')}
          </Button>
        </ModalFooter>
      </Modal>

      {errorMessage && (
        <>
          <div className='error-message'>{errorMessage}</div>
        </>
      )}
    </div>
  );
};

export default BatteryInfo;
