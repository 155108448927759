import invoke from '../../providers/ServiceProxy';

let stripeApiKey = null;

export const getStripeApiKey = () => {
  return stripeApiKey;
};

(async function () {
  stripeApiKey = await invoke({ url: '/paymentplatform/key' });
})();
